import {
  Arrow,
  Backdrop,
  // BottomTitle,
  // BottomWrapper,
  ButtonGet,
  Item,
  ItemTitle,
  ItemWrapper,
  List,
  MainStyled,
  Modal,
  ModalButton,
  ModalCode,
  ModalCodeIcon,
  ModalCopyIcon,
  ModalDescription,
  ModalImage,
  ModalItem,
  ModalList,
  ModalTitle,
  Paralelogram,
  PrizeBottomText,
  PrizeItem,
  PrizeList,
  PrizeTopText,
  PromocodeText,
  // TelegramButton,
  // TelegramDescription,
  // TelegramItem,
  TextGet,
  TextWrapper,
  TopDescription,
  TopModalWrapper,
  TopTitle,
  TopWrapper,
  TriangleIcon,
  UpperModalWrapper,
} from "./Main.styled";
import trophy from "../../assets/throphy.png";
// import question from "../../assets/question.png";
import codeButton from "../../assets/codeButton.svg";
import codeCopied from "../../assets/codeCopied.svg";
import fsIcon from "../../assets/fsIcon.png";
import { useEffect, useState } from "react";
import { getItem, getItems, getOffers } from "../../services/axios";
import triangle from "../../assets/triangle.svg";

export const Main = () => {
  const [copied, setCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState({});

  const params = new URLSearchParams(document.location.search);
  const [pid, setPid] = useState(
    params.get("pid") ?? localStorage.getItem("pidSlotoPl")
  );
  const [sub1, setSub1] = useState(
    params.get("sub1") ?? localStorage.getItem("sub1SlotoPl")
  );
  const sub2 = params.get("sub2") ?? localStorage.getItem("sub2SlotoPl");
  const sub3 = params.get("sub3") ?? localStorage.getItem("sub3SlotoPl");
  const sub4 = params.get("sub4") ?? localStorage.getItem("sub4SlotoPl");
  const sub5 = params.get("sub5") ?? localStorage.getItem("sub5SlotoPl");
  const sub6 = params.get("sub6") ?? localStorage.getItem("sub6SlotoPl");
  const sub7 = params.get("sub7") ?? localStorage.getItem("sub7SlotoPl");
  const sub8 = params.get("sub8") ?? localStorage.getItem("sub8SlotoPl");
  const hp = params.get("hp");

  if (pid !== null && pid) {
    localStorage.setItem("pidSlotoPl", pid);
  }

  if (sub1 !== null && sub1) {
    localStorage.setItem("sub1SlotoPl", sub1);
  }

  if (sub2 !== null && sub2) {
    localStorage.setItem("sub2SlotoPl", sub2);
  }

  if (sub3 !== null && sub3) {
    localStorage.setItem("sub3SlotoPl", sub3);
  }

  if (sub4 !== null && sub4) {
    localStorage.setItem("sub4SlotoPl", sub4);
  }

  if (sub5 !== null && sub5) {
    localStorage.setItem("sub5SlotoPl", sub5);
  }

  if (sub6 !== null && sub6) {
    localStorage.setItem("sub6SlotoPl", sub6);
  }

  if (sub7 !== null && sub7) {
    localStorage.setItem("sub7SlotoPl", sub7);
  }
  if (sub8 !== null && sub8) {
    localStorage.setItem("sub8SlotoPl", sub8);
  }

  useEffect(() => {
    const fetchItems = async () => {
      setItems(await getItems());
    };

    const fetchItem = async (offerArray) => {
      setItems(await getItem(offerArray));
    };

    const fetchOffers = async () => {
      try {
        const { offer: offerId, pid: pidId, sub1: sub1Id } = await getOffers();
        // const offerId = "1,4,2,6";
        // const pidId = "3";
        // const sub1Id = "slotoua";
        const offer = params.get("offer") ?? offerId;
        if (!offer) {
          fetchItems();
        } else {
          const offerArray = offer?.split(",");
          fetchItem(offerArray);
        }

        if (!pid) {
          setPid(pidId);
        }

        if (!sub1) {
          setSub1(sub1Id);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchOffers();
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const clickHandler = (t, tgLink, offerLink) => {
    // let e = "deep-link";
    // let r = true;
    // let n = {
    //   variables: {
    //     pid: pid,
    //   },
    // };
    // if (sub1 !== null) {
    //   n.variables["sub1"] = sub1;
    // }
    // if (sub2 !== null) {
    //   n.variables["sub2"] = sub2;
    // }
    // if (sub3 !== null) {
    //   n.variables["sub3"] = sub3;
    // }
    // if (sub4 !== null) {
    //   n.variables["sub4"] = sub4;
    // }
    // if (sub5 !== null) {
    //   n.variables["sub5"] = sub5;
    // }
    // if (sub6 !== null) {
    //   n.variables["sub6"] = sub6;
    // }
    // if (sub7 !== null) {
    //   n.variables["sub7"] = sub7;
    // }
    // if (sub8 !== null) {
    //   n.variables["sub8"] = sub8;
    // }
    // let a = {
    //   context: { range: [], scope: {}, variables: n },
    //   messengers: [
    //     {
    //       key: "?",
    //       name: "telegram",
    //       parameter: "start",
    //       prefixes: ["tg://", "https://t.me/"],
    //     },
    //   ],
    //   delimiter: "|",
    //   url: "/api/i/store",
    // };
    // // document
    // //   .getElementById("tg://resolve?start=ZGw6MTk5Mzc1&domain=sloto_ua_bot")
    // //   .addEventListener("click", function (t) {
    // // eslint-disable-next-line no-unused-expressions
    // t.preventDefault(),
    //   (function (e) {
    //     let t = new XMLHttpRequest();
    //     // eslint-disable-next-line no-unused-expressions
    //     t.open("POST", "https://slotoua.customer.smartsender.eu/api/i/store"),
    //       t.setRequestHeader("Content-type", "application/json"),
    //       t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
    //       (t.onreadystatechange = function () {
    //         if (4 === this.readyState && 200 === this.status) {
    //           let t = JSON.parse(this.responseText);
    //           window.location.href = (function (e, t) {
    //             let r = a.messengers.find(function (t) {
    //                 return t.prefixes.find(function (t) {
    //                   return 0 === e.indexOf(t);
    //                 });
    //               }),
    //               n = e.split(r.key),
    //               s = n[1].split("&");
    //             return (
    //               s.forEach(function (e, n) {
    //                 let i = e.split("=");
    //                 if (r.parameter === i[0]) {
    //                   let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
    //                     /=/g,
    //                     ""
    //                   );
    //                   s.splice(n, 1, [r.parameter, e].join("="));
    //                 }
    //               }),
    //               n[0] + r.key + s.join("&")
    //             );
    //           })(e, t);
    //         }
    //       }),
    //       t.send(
    //         (function (e) {
    //           return (
    //             Object.keys(a.context).forEach(function (t) {
    //               Object.keys(e).includes(t) || (e[t] = {});
    //             }),
    //             JSON.stringify(
    //               r
    //                 ? ((t = e),
    //                   new URLSearchParams(window.location.search).forEach(
    //                     function (e, r) {
    //                       t.variables.hasOwnProperty(r) || (t.variables[r] = e);
    //                     }
    //                   ),
    //                   t)
    //                 : e
    //             )
    //           );
    //           var t;
    //         })(n)
    //       );
    //   })(tgLink);
    // setTimeout(() => {
    onWebsiteBtnClick(offerLink);
    // }, 3000);
  };

  function onWebsiteBtnClick(offerLink) {
    window.location.href =
      `https://tronx.g2afse.com/click?offer_id=${offerLink}` +
      (pid !== null ? `&pid=${pid}` : "") +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "");
  }

  return (
    <MainStyled>
      <TopWrapper>
        <img src={trophy} alt="throphy" width={36} height={36} />
        <TopTitle>Ocena kasyna</TopTitle>
      </TopWrapper>
      <List>
        {items.map((item) => (
          <ItemWrapper
            key={item._id}
            className="wow animate__animated animate__fadeInUp"
          >
            <Item
              style={{
                backgroundImage: `url(${item.backgroundLink})`,
                backgroundSize: "cover",
              }}
            >
              <img src={item.logoLink} alt="logotype" width={254} height={76} />
              <ItemTitle>{item.title}</ItemTitle>
              <PrizeList>
                {item.blocks.map((block) => (
                  <PrizeItem
                    key={item._id + block.titleBlock}
                    style={{
                      border: `${
                        block.checked
                          ? "1px solid #FDF351"
                          : "1px solid rgba(255, 255, 255, 0.70)"
                      }`,
                      boxShadow: `${
                        block.checked
                          ? "0px 0px 16px 0px rgba(253, 243, 81, 0.80)"
                          : "0px 0px 16px 0px rgba(255, 255, 255, 0.50)"
                      }`,
                    }}
                  >
                    <PrizeTopText
                      style={{
                        color: `${block.checked ? " #FDF351" : "#FFF"}`,
                      }}
                    >
                      {block.titleBlock}
                    </PrizeTopText>
                    <PrizeBottomText
                      style={{
                        color: `${block.checked ? " #FDF351" : "#FFF"}`,
                      }}
                    >
                      {block.preTitleBlock}
                    </PrizeBottomText>
                  </PrizeItem>
                ))}
              </PrizeList>
              <ButtonGet
                className="deep-link"
                type="button"
                id={item.tgLink}
                onClick={(e) => {
                  setItem(item);
                  if (hp) {
                    clickHandler(e, item.tgLink, item.offerLink);
                  } else {
                    openModal();
                  }
                }}
              >
                <TextWrapper>
                  <TextGet>zdobądź kod</TextGet>
                  <Arrow
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="16"
                    viewBox="0 0 24 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                    />
                  </Arrow>
                </TextWrapper>
                <TriangleIcon src={triangle} alt="" />
                <PromocodeText>{item.backPromocode ?? "q10mt"}</PromocodeText>
                <Paralelogram
                  xmlns="http://www.w3.org/2000/svg"
                  width="87.5"
                  height="56"
                  viewBox="0 0 80 53"
                  fill="none"
                >
                  <path
                    d="M80.0003 0L80 53H0.5L19 0H80.0003Z"
                    fill="url(#paint0_linear_343_685)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_343_685"
                      x1="26"
                      y1="24"
                      x2="80"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#1450D4" />
                      <stop offset="1" stopColor="#2E6CF6" />
                    </linearGradient>
                  </defs>
                </Paralelogram>
              </ButtonGet>
            </Item>
          </ItemWrapper>
        ))}
        {/* <TelegramItem className="wow animate__animated animate__fadeInUp">
          <BottomWrapper>
            <img src={question} alt="" width={36} height={36} />
            <BottomTitle>Хочеш щось нове ?</BottomTitle>
          </BottomWrapper>
          <TelegramDescription>
            Підписуйся на наш телеграм канал та першим дізнавайся про нові
            бонуси
          </TelegramDescription>
          <TelegramButton type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="26"
              viewBox="0 0 31 26"
              fill="none"
            >
              <path
                d="M7.62209 15.1197L0.620761 12.8385C0.620761 12.8385 -0.215976 12.499 0.0534534 11.7292C0.108917 11.5705 0.2208 11.4354 0.555495 11.2033C2.1068 10.122 29.2689 0.359159 29.2689 0.359159C29.2689 0.359159 30.0359 0.100727 30.4882 0.272617C30.6001 0.307255 30.7008 0.370994 30.7799 0.457314C30.8591 0.543634 30.9139 0.649437 30.9388 0.7639C30.9877 0.966072 31.0081 1.17407 30.9995 1.38189C30.9974 1.56167 30.9756 1.7283 30.9591 1.9896C30.7937 4.65879 25.8431 24.5798 25.8431 24.5798C25.8431 24.5798 25.5469 25.7455 24.4857 25.7854C24.2249 25.7938 23.965 25.7497 23.7217 25.6555C23.4783 25.5614 23.2564 25.4192 23.0692 25.2375C20.9867 23.4461 13.7888 18.6088 12.1983 17.545C12.1624 17.5205 12.1322 17.4887 12.1097 17.4516C12.0872 17.4145 12.0729 17.3729 12.0678 17.3298C12.0456 17.2177 12.1675 17.0788 12.1675 17.0788C12.1675 17.0788 24.7008 5.93827 25.0343 4.76876C25.0601 4.67815 24.9626 4.63345 24.8316 4.67313C23.9992 4.97938 9.56858 14.0924 7.97591 15.0981C7.86126 15.1328 7.74009 15.1402 7.62209 15.1197Z"
                fill="white"
              />
            </svg>
            телеграм канал
          </TelegramButton>
        </TelegramItem> */}
      </List>
      {isOpen && (
        <Backdrop>
          <Modal>
            <UpperModalWrapper>
              <TopModalWrapper>
                <img src={fsIcon} alt="free spins" width={36} height={36} />
                <ModalTitle>
                  {item.promocode !== "sorryNoCodeToday"
                    ? "Jak ją zdobyć ?"
                    : "Bez kodu promocyjnego"}
                </ModalTitle>
              </TopModalWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  closeModal();
                }}
              >
                <path
                  d="M13.478 1.56519C7.37366 1.56519 2.52148 6.41736 2.52148 12.5217C2.52148 18.6261 7.37366 23.4782 13.478 23.4782C19.5824 23.4782 24.4345 18.6261 24.4345 12.5217C24.4345 6.41736 19.5824 1.56519 13.478 1.56519ZM17.7041 18L13.478 13.7739L9.25192 18L7.99975 16.7478L12.2258 12.5217L7.99975 8.29562L9.25192 7.04345L13.478 11.2695L17.7041 7.04345L18.9563 8.29562L14.7302 12.5217L18.9563 16.7478L17.7041 18Z"
                  fill="#333333"
                />
              </svg>
            </UpperModalWrapper>
            <ModalDescription>{item.preTitle}</ModalDescription>
            <ModalList>
              {item.steps.map((step, index) => (
                <ModalItem key={item._id + step}>
                  {`${index + 1}. ` + step}
                </ModalItem>
              ))}
            </ModalList>
            {item.promocode !== "sorryNoCodeToday" && (
              <ModalCodeIcon
                onClick={() => {
                  navigator.clipboard.writeText(item.promocode);
                  setCopied(true);

                  setTimeout(() => {
                    setCopied(false);
                  }, 5000);
                }}
              >
                {item.promocode !== "sorryNoCodeToday" && copied ? (
                  <ModalImage src={codeCopied} alt="Code copied" />
                ) : (
                  <ModalImage src={codeButton} alt="Copy code" />
                )}
                {item.promocode !== "sorryNoCodeToday" && !copied && (
                  <ModalCode>{item.promocode}</ModalCode>
                )}
                {item.promocode !== "sorryNoCodeToday" && !copied && (
                  <ModalCopyIcon
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <path
                      d="M25.8639 28.2704H11.3085V9.74534H25.8639M25.8639 7.09891H11.3085C10.6066 7.09891 9.93344 7.37773 9.43714 7.87403C8.94084 8.37033 8.66202 9.04347 8.66202 9.74534V28.2704C8.66202 28.9723 8.94084 29.6454 9.43714 30.1417C9.93344 30.638 10.6066 30.9168 11.3085 30.9168H25.8639C26.5657 30.9168 27.2389 30.638 27.7352 30.1417C28.2315 29.6454 28.5103 28.9723 28.5103 28.2704V9.74534C28.5103 9.04347 28.2315 8.37033 27.7352 7.87403C27.2389 7.37773 26.5657 7.09891 25.8639 7.09891ZM21.8942 1.80603H6.01558C5.3137 1.80603 4.64057 2.08485 4.14426 2.58115C3.64796 3.07746 3.36914 3.75059 3.36914 4.45247V22.9775H6.01558V4.45247H21.8942V1.80603Z"
                      fill="#333333"
                    />
                  </ModalCopyIcon>
                )}
              </ModalCodeIcon>
            )}
            <ModalButton
              type="button"
              id={item.buttonId}
              onClick={(e) => {
                clickHandler(e, item.tgLink, item.offerLink);
              }}
            >
              {item.promocode !== "sorryNoCodeToday"
                ? "Rejestracja"
                : "Odwiedź stronę internetową"}
            </ModalButton>
          </Modal>
        </Backdrop>
      )}
    </MainStyled>
  );
};
