import { useEffect } from "react";
import { Background, BackgroundFooter, Container } from "./App.styled";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Main } from "./components/Main/Main";
import WOW from "wowjs";

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <Background>
      <Container>
        <Header />
        <Main />
      </Container>
      <BackgroundFooter>
        <Container>
          <Footer />
        </Container>
      </BackgroundFooter>
    </Background>
  );
}

export default App;
