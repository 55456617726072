import { HeaderStyled } from "./Header.styled";
import logo from "../../assets/logotype.png";

export const Header = () => {
  return (
    <HeaderStyled>
      <img src={logo} alt="logotype" width={138} height={47.5} />
    </HeaderStyled>
  );
};
