import styled from "@emotion/styled";
import background from "./assets/background.png";
import backgroundPC from "./assets/backgroundPC.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 17px;
  flex: 2;

  @media screen and (min-width: 1000px) {
    padding: 0 125px;
  }
`;

export const Background = styled.div`
  background: linear-gradient(180deg, #cedeff 0%, #fff7ce 100%);
  // background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (min-width: 1000px) {
    // background-image: url(${backgroundPC});
  }
`;

export const BackgroundFooter = styled.div`
  background: #333333;
`;
