import styled from "@emotion/styled";

export const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 20px;
`;

export const Text = styled.p`
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #696969;
`;

export const TextBottom = styled.p`
  margin-top: 15px;
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #696969;
`;

export const Image = styled.img`
  width: 157px;
  height: 55px;
  margin-bottom: 30px;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 4;

  .is-hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const Modal = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  max-width: 425px;
  width: 100%;
  height: 311px;
  padding: 16px;
  border-radius: 14px 14px 0px 0px;
  background-color: #ffffff;
  -webkit-transform: scale(1) translateX(-50%);
  transform: scale(1) translateX(-50%);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: 1000px) {
    display: flex;
    align-items: center;
    max-width: 1182px;
    height: 131px;
    padding: 16px 16px 29px;
    gap: 15px;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

export const ModalTopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const ModalTitle = styled.p`
  color: #333;
  font-family: "Rubik";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.98px;

  @media screen and (max-width: 350px) {
    font-size: 24px;
  }
`;

export const ModalDescription = styled.p`
  max-width: 354px;
  width: 100%;
  color: #333;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.63px;

  @media screen and (min-width: 1000px) and (max-width: 1065px) {
    font-size: 16px;
  }

  @media screen and (max-width: 350px) {
    font-size: 16px;
  }
`;

export const ModalInput = styled.input`
  max-width: 393px;
  width: 100%;
  height: 70px;
  margin-top: 16px;
  margin-bottom: 12px;
  padding-left: 24px;
  border-radius: 12px;
  border: 3px solid #e4e4e4;
  background: #fcfdff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) inset;
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
  cursor: pointer;

  @media screen and (min-width: 1000px) {
    max-width: 366px;
    margin: 0;
  }
`;

export const ModalButton = styled.button`
  max-width: 393px;
  width: 100%;
  height: 74px;
  border-radius: 12px;
  background: #14fe9b;
  border: transparent;
  color: #333;
  text-align: center;
  font-family: "Rubik";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
  cursor: pointer;

  @media screen and (min-width: 1000px) {
    max-width: 366px;
    margin-right: 30px;
  }
`;

export const ModalChecked = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 386px;
  width: 100%;
  height: 212px;
  padding: 16px;
  border-radius: 14px;
  background-color: #ffffff;
  -webkit-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const ModalText = styled.p`
  max-width: 300px;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #333;
  text-align: center;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.63px;
`;

export const ModalBottomButton = styled.button`
  max-width: 354px;
  width: 100%;
  height: 74px;
  border-radius: 12px;
  background: #14fe9b;
  border: transparent;
  color: #333;
  text-align: center;
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
  cursor: pointer;
`;

export const FooterButton = styled.button`
  max-width: 334px;
  width: 100%;
  height: 58px;
  margin: 25px 0;
  border-radius: 12px;
  border: 2px solid #f6aeae80;
  background: linear-gradient(92.45deg, #f62e2e 0%, #9b1515 100%);
  font-family: Rubik;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.07px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const UnsubscribeModalBorder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  width: 100%;
  height: 293px;
  border-radius: 20px;
  background: #2e6cf666;
`;

export const UnsubscribeModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 382px;
  width: 100%;
  height: 285px;
  padding: 12px;
  border-radius: 16px;
  background: #ffffff;
`;

export const UnsubscribeModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const UnsubscribeModalTitle = styled.p`
  font-family: Rubik;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.44px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #333333;
  text-transform: uppercase;
`;

export const UnsubscribeModalText = styled.p`
  margin-bottom: 12px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #333333;
`;

export const UnsubscribeModalInput = styled.input`
  max-width: 358px;
  width: 100%;
  height: 62px;
  margin-bottom: 12px;
  padding-left: 19px;
  border-radius: 12px;
  background: #ecf5ff;
  border: 2px solid #2e6cf6;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #333333;

  &::placeholder {
    color: #8f9499;
  }
`;

export const UnsubscribeModalButton = styled.button`
  max-width: 358px;
  width: 100%;
  height: 62px;
  font-family: Rubik;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.07px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(92.45deg, #f62e2e 0%, #9b1515 100%);
  border: 2px solid #f6aeae80;
  border-radius: 12px;
  cursor: pointer;
  &:disabled {
    background: #928585;
    border: 2px solid #333;
  }
`;
