import {
  Backdrop,
  FooterButton,
  FooterStyled,
  Image,
  Modal,
  ModalBottomButton,
  ModalButton,
  ModalChecked,
  ModalDescription,
  ModalInput,
  ModalText,
  ModalTitle,
  ModalTopWrapper,
  ModalWrapper,
  Text,
  TextBottom,
  UnsubscribeModal,
  UnsubscribeModalBorder,
  UnsubscribeModalButton,
  UnsubscribeModalInput,
  UnsubscribeModalText,
  UnsubscribeModalTitle,
  UnsubscribeModalWrapper,
} from "./Footer.styled";
import logo from "../../assets/logoFooter.png";
import mail from "../../assets/mail.png";
import { useEffect, useState } from "react";
import { unsubscribePhone } from "../../services/axios";

export const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCheckedModalOpen, setIsCheckedModalOpen] = useState(false);
  const [unsubcribeModalOpen, setUnsubcribeModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const param = new URLSearchParams(document.location.search);

  const fromMail = param.get("mail");

  useEffect(() => {
    if (fromMail) {
      localStorage.setItem("isAlreadyWorkedWithEmail", true);
    }

    let isWorked = localStorage.getItem("isAlreadyWorkedWithEmail");
    if (!isWorked) {
      setTimeout(() => {
        openModal();
      }, 15000);
    }
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onPhoneNumberChange = (e) => {
    if (e.target?.value?.length > 13) {
      return;
    }
    setPhoneNumber(e.target.value);
  };

  const send = () => {
    const param = new URLSearchParams(document.location.search);

    let listId = "1322";
    const pid = param.get("pid") ?? localStorage.getItem("pidSlotoPl");
    const sub1 = param.get("sub1") ?? localStorage.getItem("sub1SlotoPl");
    const sub2 = param.get("sub2") ?? localStorage.getItem("sub2SlotoPl");
    const sub3 = param.get("sub3") ?? localStorage.getItem("sub3SlotoPl");
    const sub4 = param.get("sub4") ?? localStorage.getItem("sub4SlotoPl");
    const sub5 = param.get("sub5") ?? localStorage.getItem("sub5SlotoPl");
    const sub6 = param.get("sub6") ?? localStorage.getItem("sub6SlotoPl");
    const sub7 = param.get("sub7") ?? localStorage.getItem("sub7SlotoPl");
    const sub8 = param.get("sub8") ?? localStorage.getItem("sub8SlotoPl");

    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const email = document.querySelector(".form__inp__mail").value;

    let arr = [];
    // let link;
    // let index;
    if (!re.test(String(email).toLowerCase())) {
      alert("Niepoprawny email");
      return false;
    } else {
      for (let i = 0; i < email.length; i++) {
        arr.push(email[i]);
        // index = arr.findIndex((item) => item === "@") + 1;
        // link = arr.slice(index).join("");
      }

      fetch(`https://leads.axioma.monster/ads/new/55`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          listId,
          pid,
          sub1,
          sub2,
          sub3,
          sub4,
          sub5,
          sub6,
          sub7,
          sub8,
        }),
      });
      return true;
    }
  };

  let currentDate = new Date();

  let currentYear = currentDate.getFullYear();

  return (
    <>
      <FooterStyled>
        <Image src={logo} alt="logotype" />
        <Text>
          Strona SlotoPL nie gra w gry na pieniądze i nie ponosi
          odpowiedzialności za jakiekolwiek straty powstałe w wyniku gry w
          kasynie. Zawsze zachęcamy do korzystania z odpowiednich gier
          hazardowych.
        </Text>
        {/* <FooterButton
          type="button"
          onClick={() => {
            setUnsubcribeModalOpen(true);
          }}
        >
          Відмовитись від дзвінків
        </FooterButton> */}
        <TextBottom>© {currentYear} SlotoPL</TextBottom>
      </FooterStyled>
      {isOpen && (
        <Backdrop>
          <Modal>
            <ModalWrapper>
              <ModalTopWrapper>
                <img src={mail} alt="mail" width={36} height={36} />
                <ModalTitle>Wyjątkowy bonus</ModalTitle>
              </ModalTopWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "16px",
                }}
                onClick={() => {
                  closeModal();
                  localStorage.setItem("isAlreadyWorkedWithEmail", true);
                }}
              >
                <path
                  d="M13.478 1.56519C7.37366 1.56519 2.52148 6.41736 2.52148 12.5217C2.52148 18.6261 7.37366 23.4782 13.478 23.4782C19.5824 23.4782 24.4345 18.6261 24.4345 12.5217C24.4345 6.41736 19.5824 1.56519 13.478 1.56519ZM17.7041 18L13.478 13.7739L9.25192 18L7.99975 16.7478L12.2258 12.5217L7.99975 8.29562L9.25192 7.04345L13.478 11.2695L17.7041 7.04345L18.9563 8.29562L14.7302 12.5217L18.9563 16.7478L17.7041 18Z"
                  fill="#333333"
                />
              </svg>
              <ModalDescription>
                Wpisz swój adres e-mail i otrzymaj unikalny bonus, którego nie
                ma na stronie
              </ModalDescription>
            </ModalWrapper>
            <ModalInput
              type="text"
              placeholder="Wprowadź swój email"
              className="form__inp__mail"
            />
            <ModalButton
              type="button"
              onClick={() => {
                if (!send()) {
                  return;
                }
                localStorage.setItem("isAlreadyWorkedWithEmail", true);
                closeModal();
                setIsCheckedModalOpen(true);
              }}
            >
              Kontynuuj
            </ModalButton>
          </Modal>
        </Backdrop>
      )}
      {isCheckedModalOpen && (
        <Backdrop>
          <ModalChecked>
            <ModalTopWrapper>
              <img src={mail} alt="mail" width={36} height={36} />
              <ModalTitle>Sprawdź pocztę</ModalTitle>
            </ModalTopWrapper>
            <ModalText>
              Został wysłany na Twój adres e-mail list potwierdzający
            </ModalText>
            <ModalBottomButton
              type="button"
              onClick={() => {
                setIsCheckedModalOpen(false);
              }}
            >
              Ok
            </ModalBottomButton>
          </ModalChecked>
        </Backdrop>
      )}
      {unsubcribeModalOpen && (
        <Backdrop
          onClick={(e) => {
            if (e.target.id === "backdrop") {
              setUnsubcribeModalOpen(false);
            }
          }}
          id="backdrop"
        >
          <UnsubscribeModalBorder>
            <UnsubscribeModal>
              <UnsubscribeModalWrapper>
                <svg
                  width="36"
                  height="37"
                  viewBox="0 0 36 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_704_3488)">
                    <rect y="0.5" width="36" height="36" fill="#F62E2E" />
                    <path
                      d="M26 13.41L23.09 10.5L18 15.59L12.91 10.5L10 13.41L15.09 18.5L10 23.59L12.91 26.5L18 21.41L23.09 26.5L26 23.59L20.91 18.5L26 13.41Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_704_3488">
                      <rect
                        y="0.5"
                        width="36"
                        height="36"
                        rx="6"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <UnsubscribeModalTitle>
                  відмова від дзвінків
                </UnsubscribeModalTitle>
              </UnsubscribeModalWrapper>
              <UnsubscribeModalText>
                Введіть свій номер телефону та ми додамо його до чорного списку
                і ви більше не будете отримувати дзвінків
              </UnsubscribeModalText>
              <UnsubscribeModalInput
                type="number"
                value={phoneNumber}
                onChange={onPhoneNumberChange}
                placeholder="Ваш номер телефону"
              />
              <UnsubscribeModalButton
                type="button"
                disabled={phoneNumber?.length < 10}
                onClick={() => {
                  if (phoneNumber) {
                    unsubscribePhone(phoneNumber);
                  }

                  setUnsubcribeModalOpen(false);
                  setPhoneNumber("");
                }}
              >
                Заблокувати номер
              </UnsubscribeModalButton>
            </UnsubscribeModal>
          </UnsubscribeModalBorder>
        </Backdrop>
      )}
    </>
  );
};
