import axios from "axios";
axios.defaults.baseURL = "https://naomc.top/api/";
// axios.defaults.baseURL = "http://localhost:5000/api/";

export const getItems = async () => {
  try {
    const { data } = await axios.get("item/all");
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getItem = async (offers) => {
  try {
    const results = await Promise.all(
      offers.map((offer) => axios.get(`item/offer/${offer}`))
    );
    const data = results.map((result) => result.data).filter((item) => item);
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getOffers = async () => {
  try {
    const { data } = await axios.get("offer/all?_id=2");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const unsubscribePhone = (phone) => {
  const requestData = {
    list: "BLOCKED_FOR_AUTODIAL_PHONES",
    operation: "ADD",
    items: [phone],
  };

  const axiosConfig = {
    method: "post",
    url: "https://api.unitalk.cloud/tracking/api/lists",
    headers: {
      Authorization: "8gCE6SUHBOjy",
      "Content-Type": "application/json",
    },
    data: requestData,
  };

  axios(axiosConfig)
    .then((response) => {
      console.log("Response:", response.data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
