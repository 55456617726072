import styled from "@emotion/styled";

export const HeaderStyled = styled.header`
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #fff;
`;
