import styled from "@emotion/styled";
import button from "../../assets/button.png";
import { keyframes } from "@emotion/react";

export const MainStyled = styled.main`
  width: 100%;
  padding-top: 85px;
  padding-bottom: 18px;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;

  @media screen and (min-width: 480px) and (max-width: 1000px) {
    justify-content: center;
  }
`;

export const TopTitle = styled.p`
  font-family: "Rubik";
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.035em;
  text-align: left;
  text-transform: uppercase;

  @media screen and (max-width: 400px) {
    font-size: 34px;
  }

  @media screen and (max-width: 380px) {
    font-size: 32px;
  }

  @media screen and (max-width: 365px) {
    font-size: 30px;
  }

  @media screen and (max-width: 350px) {
    font-size: 28px;
  }

  @media screen and (max-width: 335px) {
    font-size: 26px;
  }
`;

export const TopDescription = styled.p`
  margin-top: 4px;
  margin-bottom: 22px;
  font-family: "Rubik";
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.035em;
  text-align: left;

  @media screen and (max-width: 385px) {
    font-size: 20px;
  }

  @media screen and (max-width: 350px) {
    font-size: 18px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media screen and (min-width: 480px) and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 1000px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export const ItemWrapper = styled.li`
  max-width: 390px;
  width: 100%;
  border: 4px solid rgba(46, 108, 246, 0.4);
  border-radius: 20px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-width: 390px;
  width: 100%;
  height: 283px;
  padding-top: 8px;
  border-radius: 15px;
`;

export const ItemTitle = styled.p`
  font-family: "Rubik";
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.035em;
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;

  @media screen and (max-width: 370px) {
    font-size: 26px;
  }

  @media screen and (max-width: 350px) {
    font-size: 24px;
  }

  @media screen and (max-width: 350px) {
    font-size: 22px;
  }
`;

export const PrizeItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  height: 57px;
  border-radius: 8px;
  background-color: #1a1f33cc;
`;

export const PrizeTopText = styled.p`
  font-family: "Rubik";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;

  @media screen and (max-width: 350px) {
    font-size: 21px;
  }
`;

export const PrizeBottomText = styled.p`
  font-family: "Rubik";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #fdf351;

  @media screen and (max-width: 350px) {
    font-size: 13px;
  }
`;

export const PrizeList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
  padding: 0 15px;
`;

export const Button = styled.button`
  max-width: 300px;
  width: 100%;
  height: 53px;
  border-radius: 12px;
  border: transparent;
  cursor: pointer;
  background-image: url(${button});
`;

export const TelegramItem = styled.li`
  max-width: 390px;
  width: 100%;
  height: 208px;
  padding: 12px;
  border-radius: 20px;
  border: 4px solid #2e6cf666;
  background: #ffffff;
`;

export const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const BottomTitle = styled.p`
  font-family: "Rubik";
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.035em;
  text-align: left;
  text-transform: uppercase;

  @media screen and (max-width: 400px) {
    font-size: 24px;
  }

  @media screen and (max-width: 360px) {
    font-size: 22px;
  }

  @media screen and (max-width: 340px) {
    font-size: 20px;
  }
`;

export const TelegramDescription = styled.p`
  font-family: "Rubik";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.035em;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 16px;
  }

  @media screen and (max-width: 340px) {
    font-size: 15px;
  }

  @media screen and (max-width: 330px) {
    font-size: 14px;
  }
`;

export const TelegramButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 358px;
  width: 100%;
  height: 74px;
  margin-top: 12px;
  border-radius: 12px;
  border: 2px solid rgba(174, 196, 246, 1);
  background: linear-gradient(92deg, #2ea2f6 0%, #152cfb 100%);
  cursor: pointer;

  font-family: "Rubik";
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.035em;
  text-align: right;
  color: #ffffff;

  @media screen and (max-width: 365px) {
    font-size: 29px;
  }

  @media screen and (max-width: 360px) {
    font-size: 27px;
    height: 68px;
  }

  @media screen and (max-width: 340px) {
    font-size: 23px;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);

  .is-hidden {
    opacity: 0;
    pointer-events: none;
  }
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 387px;
  width: 100%;
  min-height: 318px;
  padding: 16px;
  border-radius: 14px;
  background-color: #ffffff;
  -webkit-transform: scale(1) translate(-50%, -50%);
  transform: scale(1) translate(-50%, -50%);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
`;

export const ModalTitle = styled.p`
  color: #333;
  font-family: "Rubik";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.26px;
  @media screen and (max-width: 390px) {
    font-size: 25px;
  }
`;

export const TopModalWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const UpperModalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalDescription = styled.div`
  margin-top: 4px;
  margin-bottom: 29px;
  color: #333;
  font-family: "Rubik";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.63px;
`;

export const ModalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 29px;
`;

export const ModalItem = styled.li`
  color: #333;
  font-family: "Rubik";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.7px;
`;

export const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 355px;
  width: 100%;
  height: 74px;
  border-radius: 12px;
  border: 2px solid rgba(174, 196, 246, 0.5);
  background: linear-gradient(93deg, #1450d4 0.02%, #2e6cf6 100.02%);
  color: #fff;
  font-family: "Rubik";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.12px;
  cursor: pointer;
`;

export const ModalCode = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-family: "Rubik";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.98px;
`;

export const ModalCodeIcon = styled.div`
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const ModalCopyIcon = styled.svg`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
`;

export const ModalImage = styled.img`
  max-width: 354px;
  width: 100%;
  height: 74.7px;
`;

const becomingBlue = keyframes`
 0%{
    fill: #2E6CF6;
    color: #2E6CF6;
  }

  20%{
    fill: #2E6CF6;
    color: #2E6CF6;
  }

  80%{
    fill: #333;
    color: #333;
  }
  
  100% {
    fill: #2E6CF6;
    color: #2E6CF6;
  }
`;

export const TextGet = styled.span`
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.035em;
  text-align: left;
  text-transform: uppercase;
  animation: ${becomingBlue} 3s infinite;
`;

export const Arrow = styled.svg`
  animation: ${becomingBlue} 3s infinite;
`;

export const TextWrapper = styled.p`
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const ButtonGet = styled.button`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 300px;
  width: 100%;
  height: 53px;
  padding-left: 20px;
  border-radius: 12px;
  background-color: #ffffff;
  overflow: hidden;
  border: 2px solid #5889f8;
  cursor: pointer;

  @media screen and (max-width: 360px) {
    width: 95%;
  }
`;

const movingTringle = keyframes`
 0%{
    right: 7%;
  }
  80% {
    right: -1%;
  }

  100%{
    right: 7%;
  }
`;

export const TriangleIcon = styled.img`
  position: absolute;
  top: 0;
  right: 7%;
  z-index: 2;
  animation: ${movingTringle} 3s infinite;
`;

export const Paralelogram = styled.svg`
  position: absolute;
  border-radius: 12px;
  top: 0;
  right: -3px;
  z-index: 0;
`;

export const PromocodeText = styled.p`
  position: absolute;
  right: 4%;
  z-index: 1;
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.035em;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
`;
